@import-normalize;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
html {
  background-color: #fff;
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}

body {
  font-family: Roboto, sans-serif;
  background-color: #fff;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#root {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
}

form {
  font-family: 'Roboto', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
    sans-serif;
}
textarea {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
